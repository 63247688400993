@import 'styles/variables';

.m-delete-popup {
    border-radius: 8px;

    &__top {
        padding: 10px 16px;
        border-bottom: .5px solid;
        font-weight: 600;
    }

    &__bottom {
        display: flex;
    }

    &__btn {
        width: 50%;

        &:first-child {
            border-right: .5px solid;
        }

        button {
            background-color: transparent;
            border: none;
            width: 100%;
            padding: 14px 12px;
            cursor: pointer;
            font-weight: 600;
        }
    }
}
