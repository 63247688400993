@import 'styles/variables';

.m-cloud {
    display: flex;
    gap: 8px;
    padding: 16px 8px;
    flex-wrap: wrap;

    &__item {
        border-radius: 8px;
        padding: 10px 8px;
        width: calc(50% - 4px);
        display: flex;
        flex-direction: column;

        &-actions{
            display: flex;
            justify-content: flex-end;
            margin-top: auto;
        }
    }

    .link-icon-text__title{
        font-size: 13px;
    }

    &__desc {
        padding: 8px 0;
        overflow-wrap: break-word;
        width: 100%;
        opacity: .8;
        font-size: 11px;
    }

}
