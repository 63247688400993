@font-face {
    font-family: 'Geologica-Light';
    src: url('../assets/fonts/Geologica-Light.ttf');
}

@font-face {
    font-family: 'Geologica-Bold';
    src: url('../assets/fonts/Geologica-Bold.ttf');
}

@font-face {
    font-family: 'Xolonium-Regular';
    src: local('Xolonium-Regular'), url('../assets/fonts/Xolonium-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    unicode-range: U+000-5FF;
}

@font-face {
    font-family: 'Dameron';
    src: local('Dameron'), url('../assets/fonts/Dameron.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    unicode-range: U+000-5FF;
}
