@import 'src/styles/variables.scss';
@import 'src/styles/fonts.scss';

.confirm-email {
    display: flex;
    flex-direction: column;
    margin: 0;
    height: 100%;

    &__header {
        display: flex;
        justify-content: center;
        padding: 23px 10px;
    }

    &__loader {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__body {
        display: flex;
        flex-direction: column;
        padding: 0 16px;
        align-items: center;
        margin-top: 50px;
    }

    &__confirmed-logo {
        max-width: 582px;
        margin-bottom: 32px;

        @media ( max-width: $device-small-2 ) {
            width: 100%;
        }
    }

    &__err-logo {
        max-width: 262px;
        margin-bottom: 32px;

        @media ( max-width: $device-small ) {
            max-width: 160px;
        }
    }

    &__title {
        margin: 0;
        font-family: $font-acrom-bold;
        text-align: center;
        margin-bottom: 24px;
        font-size: 36px;
        color: $color-dark-brown;

        @media ( max-width: $device-small-2 ) {
            font-size: 24px;
        }
    }

    &__description {
        margin: 0;
        max-width: 960px;
        font-size: 18px;
        color: #555554;
        font-family: $font-acrom-regular;
        text-align: center;
        margin-bottom: 32px;

        @media ( max-width: $device-small-2 ) {
            font-size: 14px;
        }
    }
}
