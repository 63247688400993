@import 'styles/variables.scss';

.m-payments{
    &__wrapper{
        display: flex;
        flex-direction: column;
        padding: 16px 8px;
        gap: 16px;

        .m-payment{
            border: 0;
            padding: 8px;
            display: flex;
            flex-wrap: nowrap;
            gap: 12px;
            border-radius: 12px;
            cursor: pointer;
            
            &__image{
                width: 30px;
                height: 30px;
                border-radius: 10px;
            }

            &__title{
                font-family: $font-acrom-bold;
                font-size: 16px;
                line-height: 22px;
                word-wrap: break-word;
                max-width: 95px;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                overflow: hidden;
                -webkit-box-orient: vertical;  
            }
            &__status{
                white-space: nowrap;
                padding: 4px 8px;
                border-radius: 50px;
                font-size: 13px;
                line-height: 16px;
                font-family: $font-acrom-bold;

                &.success{
                    background-color: #4CAF501A;
                    color: #4CAF50;
                }

                &.cancel{
                    background-color: #FF98001A;
                    color: #FF9800;
                }
                &.processing{
                    background-color: $color-grey-ultralight;
                    color: $color-dark-grey-lighter;
                }
            }

            &__info{
                flex-grow: 1;
                gap: 8px;
                display: flex;
                flex-direction: column;
                width: calc(100% - 50px);
            }
            &__block{
                display: flex;
                justify-content: space-between;
                gap: 8px;
                align-items: flex-start;
                &:last-child{
                    align-items: center;
                }
            }
            &__date{
                font-family: $font-acrom-bold;
                font-size: 13px;
                line-height: 16px;
                opacity: .8;
            }

            &__link{
                display: flex;
                align-items: center;
            }
        }
    }

    &__no-data{
        padding: 16px;
        text-align: center;
        border-radius: 16px 8px;

        &-title{
            font-size: 17px;
            line-height: 22px;
            font-family: $font-acrom-bold;
            margin-bottom: 8px;
        }
        &-text{
            font-family: $font-acrom-regular;
            font-size: 13px;
            line-height: 16px;
        }
    }
}

