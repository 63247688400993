@import 'styles/variables';

.follow-us {
    margin: 35px 0 0;
    text-align: center;

    &__title {
        margin-bottom: 16px;
        font-family: $font-acrom-bold;
        font-size: 18px;
    }

    &__socials {
        display: flex;
        column-gap: 12px;
        justify-content: center;

        button {
            padding: 0;
            background-color: transparent;
            height: 22px;
            border: none;
            cursor: pointer;

            svg {
                width: 22px;
                height: 22px;
                color: $color-light-white;
            }
        }
    }
}
