@import 'src/styles/variables.scss';
@import 'src/styles/fonts.scss';

.page-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh;
    background: url('../../assets/images/authBg.png') center center / cover no-repeat fixed;

    &__language {
        position: absolute;
        top: 40px;
        right: 50px;

        @media ( max-width: 650px ) {
            top: 20px;
            right: 0;
        }
    }

    .module-logo {
        position: absolute;
        top: 50px;
        z-index: 1;

        @media ( max-width: 650px ) {
            top: 70px;
        }
    }

    .back-btn {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 40px;
        left: 50px;
        z-index: 1;
        font-size: 18px;
        color: $color-dark-brown;
        font-family: $font-acrom-bold, sans-serif;
        text-decoration: none;
        line-height: 38px;

        img {
            margin-bottom: 2px;
            margin-right: 10px;
        }

        @media ( max-width: 650px ) {
            top: 20px;
            left: 20px;
        }
    }

    .header {
        position: relative;
        margin-bottom: 25px;

        .title {
            font-size: 32px;
            color: $color-dark-brown;
            font-family: $font-acrom-bold, sans-serif;
        }

        p {
            font-size: 18px;
            color: $color-dark-brown;
            font-family: $font-acrom-regular, sans-serif;
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            height: 1px;
            background: $color-light-grey-2;
        }
    }

    &--heading {
        font-family: $font-acrom-bold, sans-serif;
        font-size: 32px;
        color: $color-dark-brown;
        margin-bottom: 24px;

        @media ( max-width: $device-small ) {
            font-size: 24px;
        }
    }

    &--forgot {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        font-size: 16px;
        color: $color-dark-brown;
        font-family: $font-acrom-regular, sans-serif;

        label {
            display: flex;
            justify-content: center;
            align-items: center;

            @media ( max-width: 650px ) {
                align-self: flex-start;
                margin-bottom: 10px;
            }
        }

        .login-checkbox {
            width: 18px;
            height: 18px;
            margin-right: 6px;
            accent-color: $color-dark-black;
        }

        @media ( max-width: 650px ) {
            flex-direction: column;
        }
    }

    &--delimiter {
        font-size: 18px;
        color: $color-dark-brown;
        font-family: $font-acrom-regular, sans-serif;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 14px;
            left: 0;
            height: 1px;
            width: 45%;
            background: $color-dark-brown;
        }

        &::after {
            content: '';
            position: absolute;
            top: 14px;
            right: 0;
            height: 1px;
            width: 45%;
            background: $color-dark-brown;
        }
    }

    form {
        display: flex;
        flex-direction: column;
        width: 500px;
        margin-top: 110px;
        background: $color-light-white;
        box-shadow: 0 174px 70px rgba(0, 0, 0, .01), 0 98px 59px rgba(0, 0, 0, .04), 0 44px 44px rgba(0, 0, 0, .07), 0 11px 24px rgba(0, 0, 0, .08), 0 0 0 rgba(0, 0, 0, .08);
        border-radius: 8px;
        text-align: center;
        padding: 25px 40px;
        box-sizing: border-box;
        z-index: 1;

        @media ( max-width: 650px ) {
            width: 360px;
            padding: 25px 30px;
            margin-bottom: 100px;
        }
    }

    .password {
        position: relative;

        &__btn {
            border: none;
            background-color: transparent;
            position: absolute;
            bottom: 10px;
            right: 10px;
            z-index: 1;
            cursor: pointer;
        }

        .icon {
            position: absolute;
            top: 33px;
            right: 15px;
            width: 25px;
            height: 25px;
            cursor: pointer;
        }
    }
}

.auth-form-button {
    margin: 17px 0;

    button {
        width: 100%;
        margin: 0;
    }
}

.auth-form-socials {

    button {

        svg {
            display: flex;
            flex-shrink: 0;
        }
    }

    &--btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 54px;
        background: transparent;
        border-radius: 8px;
        border: 1px solid $color-blue;
        color: $color-blue;
        font-size: 18px;
        font-family: $font-acrom-bold, sans-serif;
        cursor: pointer;

        &:hover {
            border: none;
            background: $color-blue;
            color: $color-light-white;
        }
        @media (max-width:$device-small) {
            font-size: 16px;
        }
    }
}

.mt-10 {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 54px;
    background: transparent;
    border-radius: 8px;
    border: 1px solid $color-red;
    color: $color-red;
    font-size: 18px;
    font-family: $font-acrom-bold, sans-serif;
    cursor: pointer;

    @media (max-width:$device-small) {
        font-size: 16px;
    }

    div {
        width: auto;
        align-items: center;
    }

    &:hover {
        border: none;
        background: $color-red;
        color: $color-light-white;
    }
}

.auth-form-bottom {

    &--account {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 30px;
    }

    &--account-text {
        font-size: 16px;
        margin-right: 10px;
        font-family: $font-acrom-regular, sans-serif;
        color: $color-dark-brown;
        display: flex;
        gap: 4px;

        @media ( max-width: 650px ) {
            flex-direction: column;
        }

        @media (max-width:$device-small) {
            font-size: 14px;
        }
    }

    &--link-base {
        font-size: 16px;
        font-family: $font-acrom-bold, sans-serif;
        color: $color-dark-black;
        text-decoration: none;
        transition: opacity .15s;

        &:hover {
            opacity: .62;
        }

        &:active {
            opacity: .4;
        }
    }
}

.auth-form {

    &__tutorial {
        margin-top: 20px;
        font-size: 16px;
        font-family: $font-acrom-bold, sans-serif;
        color: $color-dark-brown;
        text-decoration: none;
        transition: opacity .15s;

        &:hover {
            opacity: .62;
        }
    }
}
