@import 'styles/variables.scss';

.new-loader__content, .right-emulator__page{
    .react-calendar{
        &__navigation{
            &__label {
                font-size: 17px;
                line-height: 24px;
                letter-spacing: -.41px;

                 &::first-letter{
                    text-transform: capitalize;
                }
            }
        }
    }
}