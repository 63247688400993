@import 'styles/variables';

.m-todo {
    padding: 16px;

    &__categories {
        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__active-category {
        outline: '1px solid';
    }
}
