// @import 'navigation/navigation';

.dashboard-constructor {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 80px;

    @media ( max-width: 960px ) {
        padding-top: 76px;
    }
    &.next-feature-show{
        padding-bottom: 86px;

        @media (max-width:$device-small-1) {
            padding-bottom: 138px;
        }
    }
}

.dashboard-constructor--sale {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 160px;

    @media ( max-width: 960px ) {
        // padding-top: 132px;
        padding-top: 156px;
    }

    &.next-feature-show{
        padding-bottom: 86px;
        @media (max-width:$device-small-1) {
            padding-bottom: 138px;
        }
    }
}

.dashboard-view {
    width: 100%;
    padding: 0;
    position: relative;
    padding-left: 400px;
    padding-right: 367px;

    @media ( max-width: 1600px ) {
        padding-right: 0;
    }

    @media ( max-width: $device-medium-2 ) {
        padding-left: 320px;
    }

    @media ( max-width: 960px ) {
        padding-left: 0;
        padding-bottom: 170px;
    }


    &--less-padding,&--no-padding {
        padding-left: 200px;
        padding-right: 0;

        @media ( max-width: $device-medium-2 ) {
            padding-left: 160px;
        }

        @media ( max-width: 960px ) {
            padding-left: 0;
        }
        
        @media (max-width:$device-small) {
            padding-bottom: 100px;
        }
    }

    &--no-padding{
        .inner-container{
            max-width: 1400px;
            margin-left: 0;
        }
    }

    &--wide {
        padding-left: 0;
        padding-right: 0;
    }
}
