@import 'styles/variables.scss';

.m-billing {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 0;
    cursor: pointer;
    border: none;
    position: relative;

    &__wrapper {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__image {
        border-radius: 8px 8px 0 0;
        flex-shrink: 0;
        width: 100%;
        max-height: 140px;
    }

    &__info {
        display: grid;
        grid-template-columns: 1fr;
        text-align: start;
        flex-grow: 1;
        padding: 16px;
    }

    &__title {
        text-transform: capitalize;
        font-family: $font-acrom-bold;
        font-size: 17px;
        text-overflow: ellipsis;
        width: calc(92%);
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
    }

    &__desc {
        font-family: $font-acrom-regular;
        font-size: 13px;
        opacity: .8;
        text-overflow: ellipsis;
        width: calc(92%);
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
    }
}
