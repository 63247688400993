@import 'styles/variables';

.m-task {
    display: flex;
    border-radius: 13px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

    &__wrapper {
        padding: 10px 16px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-shrink: 0;
        border-radius: 13px;
        cursor: grab;
        position: relative;
        z-index: 1;
        gap: 5px;
    }

    &__info {
        display: flex;
        gap: 10px;
        align-items: center;
        opacity: .8;
    }

    &__actions {
        display: flex;
        gap: 10px;
    }

    &__check {
        padding: 0;
        background-color: transparent;
        border: none;
        height: 18px;
    }

    &__text {
        word-break: break-all;
        hyphens: auto;

        &--done {
            text-decoration: line-through;
        }
    }

    &__priority {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__button {
        background-color: transparent;
        border: none;
        padding: 0;
        cursor: pointer;
    }

    &__moves {
        display: flex;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            background-color: #219653;
            width: 8px;
            height: 100%;
            left: -8px;
            top: 0;
        }
    }

    &__move {
        padding: 0 18px;
        background-color: #219653;

        &:last-child {
            background-color: #eb5757;
        }
    }
}
