@import 'styles/variables.scss';

.m-calendar-item {
    hyphens: auto;
    padding: 8px 10px;
    border-radius: 8px;
    display: flex;
    gap: 5px;

    &__image {
        border-radius: 4px;
        flex-shrink: 1;
        width: 40%;
        max-height: 90px;
    }

    &__block {
        font-size: 12px;
        line-height: 15px;
        flex-grow: 1;
    }

    &__title {
        font-family: $font-acrom-bold;
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 148px;
    }

    &__info {
        text-overflow: ellipsis;
        width: calc(99%);
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
        opacity: .8;
    }

    p {
        margin: 0;
    }
}
