@import 'styles/variables.scss';

.m-service-item {
    display: flex;
    height: 100%;
    flex-direction: column;

    &__info {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 16px;
        flex-grow: 1;
    }

    &__title {
        hyphens: auto;
        font-size: 25px;
        font-family: $font-acrom-bold;
    }

    &__date {
        font-size: 13px;
        font-family: $font-acrom-regular;
        opacity: .8;
        padding-bottom: 10px;
        border-bottom: .5px solid;
    }

    &__desc {
        hyphens: auto;
        font-size: 16px;
        font-family: $font-acrom-regular;
        opacity: .8;

        a {
            color: inherit;
        }
    }

    &__values {
        display: flex;
        gap: 6px;
        padding-bottom: 10px;
    }

    &__border {
        border-bottom: .5px solid;
        opacity: .15;
    }

    &__tag {
        position: relative;
        padding: 4px 8px;
        font-weight: 600;
        display: flex;
        gap: 4px;
        align-items: center;
    }

    &__background {
        border-radius: 999px;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: .15;
        top: 0;
        right: 0;
    }
}
