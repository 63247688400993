@import './fonts.scss';
@import 'src/styles/variables.scss';

// constructor dashboard
@import '../layouts/dashboard/dashboard.scss';

html {
    font-size: 100%;
    width: 100%;
    height: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    color: $color-dark-brown;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Geologica', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    line-height: 1.52947;
    // font-weight: 600;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    // -webkit-user-select: none;
    // user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    direction: ltr;
    color: $color-dark-brown;
    background-color: $color-light-white;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        display: none;
    }
}

img {
    display: inline-block;
    max-width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
}

.constructor-wrapper {
    padding-top: 80px;

    @media ( max-width: 960px ) {
        padding-top: 52px;
    }

    &__sale {
        padding-top: 160px;

        @media ( max-width: 960px ) {
            padding-top: 132px;
        }
    }
}

.inner-container {
    position: relative;
    max-width: 1200px;
    margin: auto auto 20px auto;
    padding: 0 40px;

    @media ( max-width: $device-small ) {
        padding: 0 16px;
    }
}

.relative {
    position: relative;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 3fr));
    grid-column-gap: 15px;
    grid-row-gap: 15px;
}

.page-title {
    text-align: center;
}

.add-right-space {
    margin-right: 5px;
}

.pointer {
    cursor: pointer;
}

.acrom-regular {
    font-family: $font-acrom-regular;
}

.acrom-bold {
    font-family: $font-acrom-bold;
}

.hide {
    display: none;
}

.button-icon {
    margin-right: 0;
}

.color-green {
    color: $color-green;
}

.bg-green {
    background-color: $color-green;
}

.color-black {
    color: $color-dark-black;
}

.bg-black {
    background-color: $color-dark-black;
}

.color-grey {
    color: $color-grey;
}

.bg-grey {
    background-color: $color-grey;
}

.table-image {

    img {
        height: 45px;
        border-radius: 8px;
    }
}

#root,
#shell,
.root {
    min-width: 320px;
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
}

// For emulator

// For text-editor

.ql-editor {
    min-height: 200px;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.ql-clipboard {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.block {
    padding: 16px;
    border: 1px solid $color-grey-ultralight;
    border-radius: 8px;
    margin-bottom: 20px;
    color: $color-dark-brown;

    @media ( max-width: $device-small-2 ) {
        padding: 16px 10px;
    }

    &--big {
        padding: 20px 30px;
        margin-bottom: 30px;

        @media ( max-width: $device-small ) {
            padding: 20px;
        }
    }

    &--white {
        background-color: $color-light-white;
    }

    &--grey {
        background-color: $color-light-grey-lighter;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid $color-grey-ultralight;
        padding-bottom: 16px;
        margin-bottom: 16px;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;

        @media ( max-width: $device-small ) {

            .custom-button__wrapper {
                width: 100%;

                button {
                    width: 100%;
                }
            }
        }
    }

    &__title {
        font-size: 22px;
        font-family: $font-acrom-bold;
        margin: 0;
        line-height: normal;
        display: flex;
        justify-content: space-between;
        width: fit-content;
        align-items: center;

        @media ( max-width: $device-small-2 ) {
            font-size: 18px;
        }

        &--big {
            font-size: 24px;

            @media ( max-width: $device-small-2 ) {
                font-size: 20px;
            }
        }
    }

    &__subtitle {
        font-size: 18px;
        font-family: $font-acrom-regular;
        margin-top: 8px;

        @media ( max-width: $device-small ) {
            font-size: 14px;
        }
    }
}
