@import 'src/styles/variables.scss';
@import 'src/styles/fonts.scss';

.m-empty{
    &__title{
        font-family: $font-acrom-regular;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: -0.41px;
        color: $color-dark-black;
        text-align: center;
        margin-top: 150px;
        margin-bottom: 20px;
        padding: 0 10px;
    }

    &__minion{
        height: 145px;
        width: 73px;
        margin: 0 auto;
        
        img{
            width: 100%;
            height: 100%;
        }
    }
}