@import 'styles/variables';

.m-map-item {
    display: flex;
    height: calc(100% - 87px);
    flex-direction: column;

    #map {
        height: 200px !important;
        flex-shrink: 0;
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 16px;
        flex-grow: 1;
    }

    &__title {
        hyphens: auto;
        font-size: 25px;
        font-family: $font-acrom-bold;
    }

    &__subtitle {
        font-size: 13px;
        font-family: $font-acrom-regular;
        opacity: .8;
        padding-bottom: 10px;
        border-bottom: .5px solid;
    }

    &__image {
        margin-top: 16px;
        border-radius: 15px;
    }

    &__desc {
        hyphens: auto;
        font-size: 16px;
        font-family: $font-acrom-regular;
        opacity: .8;

        p {
            margin: 16px 0;
        }

        a {
            color: inherit;
        }
    }
}
