@import 'styles/variables';

.emulator-header{
    &__language{
        position: relative;
        &-choose{
            background-color: transparent;
            border: 0;
            font-size: 18px;
            font-family: $font-acrom-bold;
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }

    .language-choose__list{
        position: absolute;
        width: calc(100% - 40px);
        top: 100px;
        left: 20px;
        z-index: 9;
    }
   
}