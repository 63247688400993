@import 'styles/variables';

.new-loader {

    &__iphone {
        position: absolute;
        top: 0;
        left: -5px;
        pointer-events: none;
        z-index: 11;

        // @media ( max-height: 1100px ) {
        // left: -5px;
        // }
    }

    &__wrapper-small {
        position: relative;
        padding: 11px 11px 14px 9px;
        border-radius: 43px;
        width: 297px;
        height: 629px;
        box-sizing: border-box;
    }

    &__wrapper {
        position: relative;
        width: 417px;
        height: 878px;
        padding: 13px 14px 14px 11px;
        background-color: wheat;
        border-radius: 53px;
        box-sizing: border-box;
        overflow-y: hidden;

        &::-webkit-scrollbar {
            display: none;
        }

        @media ( max-height: 1100px ) {
            padding: 13px 13px 14px 9px;
            border-radius: 53px;
            width: 324px;
            height: 681px;
        }

        @media ( max-height: 800px ) {
            padding: 11px 10px 14px 8px;
            border-radius: 43px;
            width: 297px;
            height: 629px;
        }
    }

    &__centered {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    &__data {
        padding: 15px;
    }

    &__content {
        flex-grow: 1;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}
