@import 'styles/variables';

.loader-select {
    font-size: 15px;
    font-family: $font-acrom-regular;
    border: none;
    padding: 11px 16px;
    border-radius: 8px;

    &--empty {
        opacity: .8;
    }

    &__wrapper {
        position: relative;
    }

    &__arrow {
        position: absolute;
        background-color: transparent;
        border: none;
        right: 5px;
        margin: 0;
        padding: 0;
        height: 22px;
        cursor: pointer;

        &--up {
            transform: rotate(180deg);
        }
    }

    &__item-icon {
        width: 22px;
        height: 22px;
    }

    &__info {
        display: flex;
        gap: 10px;
        align-items: center;
        width: 100%;

        &--left {
            flex-direction: row;
            justify-content: flex-start;
        }

        &--between {
            flex-direction: row-reverse;
            justify-content: space-between;
        }
    }

    &__menu {
        width: 200px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 1;
        right: 0;
        top: 50px;
        max-height: 150px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: inherit;
        }
    }

    &__item {
        border: none;
        background-color: transparent;
        border-bottom: 1px solid;
        padding: 10px;
        cursor: pointer;

        &:last-child {
            border-bottom: none;
        }
    }
}
