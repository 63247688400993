//colors
$color-primary: #ffed00;
$color-light-white: #ffffff;
$color-light-grey: #d6d6d6;
$color-light-blue: #f2f2f2;
$color-dark-grey: #929598;
$color-dark-red: #f12727;
$color-dark-yellow: #d1a906;
$color-dark-brown: #2b2a29;
$color-dark-grey-2: #9d9e9e;

//new design vars
$color-dark-grey-lighter: #8b90b1;
$color-light-grey-2: #c8c8c8;
$color-dark-black: #000000;
$color-light-grey-lighter: #fafafa;
$color-grey: #ededed;
$color-grey-light: #d9d9d9;
$color-grey-ultralight: #f2f2f2;
$color-grey-dark: #555554;
$color-blue: #2f80ed;
$color-blue-light:#008CFF;
$color-red: #e22c2c;
$color-green:#4caf50;
$color-light-green:#d2ebd3;
$color-dark-green:#76A62D;
$color-grey-blue:#636E7E;

//fonts
$font-xolonium-regular: 'Xolonium-Regular';
$font-acrom-regular: 'Geologica-Light';
$font-acrom-bold: 'Geologica-Bold';
$font-dameron: 'Dameron';

//media
$device-medium-1: 1600px;
$device-medium-2: 1420px;
$device-medium-3: 1360px;
$device-medium-4: 1200px;
$device-small-1: 920px;
$device-small-2: 800px;
$device-small-3: 720px;
$device-small: 560px;
$device-small-5: 500px;
$device-small-4: 400px;
//media
