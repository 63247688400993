@import 'styles/variables.scss';


.m-icon__page{
    width: 256px;

    &-title{
        font-size: 22px;
        line-height: 26px;
        font-family: $font-acrom-bold;
        color: $color-dark-brown;
        margin-bottom: 24px;
    }

    &-preview{
        width: 256px;
        height: 256px;
        border-radius: 12px;
        overflow: hidden;
        
        img{
            width: 100%;
            height: 100%;
        }
    }

    &-noImage{
        width: 256px;
        height: 256px;
        border: 4px solid $color-light-grey-2;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg{
            width: 208px;
            height: 208px;
        }
    }
}