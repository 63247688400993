@import 'styles/variables.scss';

.menu-item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    list-style: none;
    cursor: pointer;
    font-size: 18px;
    font-family: $font-acrom-bold;
    margin-top: 10px;

    // @media ( max-width: $device-small-2 ) {
    //     font-size: 14px;
    // }

    @media ( max-width: 960px ) {
        font-size: 10px;
        line-height: 12px;
        margin-top: 0;
        @media ( max-width: 960px ) {
            height: 100%;
        }
    }


    &__soon {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        background-color: rgba(43, 42, 41, .35);
        border-radius: 8px;
        cursor: not-allowed;
    }

    &__soon-text {
        width: 100%;
        background-color: $color-primary;
        text-align: center;
        font-family: $font-acrom-bold;

        @media ( max-width: $device-small-2 ) {
            font-size: 14px;
        }
    }

    &__wrapper {
        position: relative;
        // @media ( max-width: $device-small-2 ) {

        //     &:last-child {
        //         margin-bottom: 80px;
        //     }
        // }
        &.preview-item{
            @media ( max-width: 960px ) {
                opacity: 0;
                pointer-events: none;
            }
            .menu-item{
                border-radius: 8px;
                overflow: hidden;
            }
           
            button{
                background-color: $color-primary;
                border-color: #E8D800;

                &:hover{
                    background-color: #E8D800;
                }
                
            }
            .menu-item__button--active {
                background: $color-dark-brown;
                color: $color-primary;
                box-shadow: none;
                border: none;
    
                &:hover {
                    background-color: $color-dark-brown;
                }
            }
        }
    }

    &__button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        background-color: $color-light-grey-lighter;
        border-radius: 8px;
        font-family: $font-acrom-bold;
        font-size: 16px;
        text-transform: uppercase;
        border-width: 0 1px 3px 1px;
        width: 180px;
        border-style: solid;
        color: $color-dark-brown;
        border-color: $color-grey-ultralight;
        box-shadow: 0 8px 3px rgba(0, 0, 0, .01), 0 4px 3px rgba(0, 0, 0, .05), 0 2px 2px rgba(0, 0, 0, .09), 0 0 1px rgba(0, 0, 0, .1), 0 0 0 rgba(0, 0, 0, .1);

        @media ( max-width: $device-medium-2 ) {
            width: 140px;
            padding: 8px;
            font-size: 14px;
        }

        @media ( max-width: 960px ) {
            width: 100px;
            padding: 4px 2px;
            font-size: 10px;
            line-height: 12px;
            height: 100%;
            text-transform: none;
            justify-content: center;
        }

        @media ( max-width: $device-small ) {
            width: 65px;
            padding: 8px;
        }

        &:hover {
            background-color: $color-grey-ultralight;
            cursor: pointer;
        }

        &--active {
            background: $color-dark-brown;
            color: $color-primary;
            box-shadow: none;
            border: none;

            &:hover {
                background-color: $color-dark-brown;
            }
        }
    }

    &__name {
        margin-top: 10px;
        width: 145px;
        text-overflow: ellipsis;
        // white-space: nowrap;
        word-wrap: break-word;
        overflow: hidden;
        @media ( max-width: $device-medium-2 ) {
            width: 120px;
        }

        
        @media ( max-width: 960px ) {
            width: 95px;
            margin-top: 2px;
        }

        @media ( max-width: $device-small ) {
            width: 60px;
        }
    }

    &__icon {
        color: $color-dark-brown;

        @media ( max-width: 960px ) {
            width: 30px;
            height: 30px;
            
            svg{
                height: 100%;
                width: 100%;
            }
        }

        &--active {
            color: $color-primary;
        }
    }
}
