@import 'styles/variables';

.m-task-popup {
    border-radius: 13px;
    display: flex;
    flex-direction: column;

    &__header {
        padding: 16px;
        padding-bottom: 12px;
        border-bottom: 1px solid;
        display: flex;
        justify-content: space-between;

        button {
            border: none;
            background-color: transparent;
            padding: 0;
            align-self: flex-start;
            cursor: pointer;
        }
    }

    &__title {
        font-family: $font-acrom-bold;
        font-size: 17px;
        margin-top: 20px;
    }

    &__body {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__button {
        width: 100%;
        margin-top: 14px;
    }
}
