@import 'styles/variables.scss';

.cookie {
    position: fixed;
    bottom: 10px;
    right: 50%;
    transform: translate(50%, 0);
    z-index: 10;
    padding: 30px;
    background-color: $color-light-white;
    border-radius: 15px;
    color: $color-dark-brown;
    width: 452px;
    box-shadow: 0 4px 25px rgba(0, 0, 0, .15);

    @media ( max-width: $device-small-2 ) {
        padding: 20px;
        width: 345px;
    }

    &__image {
        width: 52px;
        height: 105px;
        flex-shrink: 0;

        @media ( max-width: $device-small-2 ) {
            width: 42px;
            height: 85px;
        }
    }

    &__info {
        position: relative;
        display: flex;
        column-gap: 20px;
        margin-bottom: 30px;

        @media ( max-width: $device-small-2 ) {
            margin-bottom: 20px;
            column-gap: 16px;
        }
    }

    &__header {
        font-size: 24px;
        font-family: $font-acrom-bold;
        margin-bottom: 10px;

        @media ( max-width: $device-small-2 ) {
            font-size: 20px;
            line-height: 24px;
        }
    }

    &__text {
        font-size: 18px;
        font-family: $font-acrom-regular;
        line-height: 22px;

        @media ( max-width: $device-small-2 ) {
            font-size: 14px;
            line-height: 17px;
        }

        a {
            font-family: $font-acrom-bold;
            text-decoration: underline;

            &:visited {
                color: $color-dark-brown;
            }
        }
    }

    button {
        width: 100%;
        margin: 0;
    }
}
