@import 'styles/variables.scss';

.industry-popup {

    &__image {
        width: 90px;
        padding: 32px 0;
        margin: 0 auto;

        @media ( max-width: $device-small ) {
            width: 65px;
            padding: 16px 0;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 16px;
    }

    &__item {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 18px;
        font-family: $font-acrom-regular;
        position: relative;

        input {
            visibility: hidden;
            margin: 0;
        }

        label::before{
            width: 15px;
            height: 15px;
            border-radius: 15px;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            position: absolute;
            background-color: $color-light-white;
            content: '';
            display: inline-block;
            visibility: visible;
            outline: 1px solid $color-dark-brown;
            border: 3.5px solid $color-light-white;
        }

        &--error {

            label::before {
                width: 15px;
                height: 15px;
                border-radius: 15px;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                position: absolute;
                background-color: $color-light-white;
                content: '';
                display: inline-block;
                visibility: visible;
                outline: 2px solid #f12727;
                border: 3.5px solid $color-light-white;
            }
        }

        input:checked ~ label::before {
            width: 15px;
            height: 15px;
            border-radius: 15px;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            background-color: $color-dark-brown;
            content: '';
            display: inline-block;
            visibility: visible;
            outline: 1px solid $color-dark-brown;
            border: 3.5px solid $color-light-white;
        }
    }

    &__note {
        font-size: 18px;
        font-family: $font-acrom-regular;
        margin: 0;
    }
}
