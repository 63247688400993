@import 'styles/variables.scss';

.m-service {
    padding: 8px;
    border-radius: 13px;
    display: flex;
    gap: 6px;
    cursor: pointer;
    border: none;
    position: relative;

    &__wrapper {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__image {
        border-radius: 4px;
        flex-shrink: 1;
        width: 72px;
        height: 72px;
    }

    &__info {
        display: grid;
        grid-template-columns: 1fr;
        gap: 6px;
        text-align: start;
        flex-grow: 1;
    }

    &__title {
        text-transform: capitalize;
        font-family: $font-acrom-bold;
        font-size: 17px;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(92%);
        overflow: hidden;
    }

    &__desc {
        font-family: $font-acrom-regular;
        font-size: 13px;
        opacity: .8;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(92%);
        overflow: hidden;
    }

    &__read-more {
        font-size: 13px;
        font-family: $font-acrom-bold;
        position: absolute;
        bottom: 10px;
        right: 16px;
    }

    &__values {
        display: flex;
        gap: 6px;
        margin-bottom: 20px;
    }

    &__tag {
        position: relative;
        padding: 4px 8px;
        font-weight: 600;
        display: flex;
        gap: 4px;
        align-items: center;
    }

    &__background {
        border-radius: 999px;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: .15;
        top: 0;
        right: 0;
    }
}
