@import 'styles/variables';

.m-edit-category {
    border-radius: 8px;
    font-size: 15px;
    font-weight: 600;
    position: absolute;
    cursor: pointer;

    &__wrapper {
        width: 16px;
        height: 16px;
    }

    &__menu {
        padding: 0;
        border: none;
        background-color: transparent;
        width: 16px;
        height: 16px;
        cursor: pointer;

        svg {
            width: 16px;
            height: 16px;
        }
    }

    &__action {
        display: flex;
        gap: 6px;
        padding: 8px 12px;
        align-items: center;
        width: 100%;
        background-color: transparent;
        border: none;
        font-weight: 600;

        &:first-child {
            border-bottom: 1px solid;
        }
    }
}
