@import 'styles/variables';

.m-todo-add {
    position: absolute;
    bottom: 36px;
    right: 30px;
    padding: 11px;
    width: 48px;
    height: 48px;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    z-index: 2;

    &__close {

        svg {
            transform: rotate(45deg);
        }
    }

    &__actions {
        border-radius: 8px;
        width: 195px;
        position: absolute;
        right: 30px;
        bottom: 100px;

        button {
            border: none;
            font-size: 17px;
            text-align: right;
            padding: 8px 16px;
            width: 100%;
            background-color: transparent;
            color: inherit;
            font-family: $font-acrom-bold;
            cursor: pointer;

            &:first-child {
                border-bottom: 1px solid;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }

            &:last-child {
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
            }
        }
    }
}
