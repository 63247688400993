@import 'styles/variables.scss';

.ai-popup {
    margin: 0;

    &__image {
        width: 175px;
        margin: 30px auto;

        @media ( max-width: $device-small ) {
            width: 129px;
            margin: 16px auto;
        }
    }

    &__info {
        font-size: 18px;
        margin-top: 30px;
        margin-bottom: 10px;

        @media ( max-width: $device-small ) {
            font-size: 14px;
        }
    }

    &__title {
        font-family: $font-acrom-bold;
        font-size: 18px;

        @media ( max-width: $device-small ) {
            font-size: 14px;
        }
    }

    &__list {
        margin: 0;
        padding-left: 20px;
        font-size: 18px;

        div {
            display: flex;
            align-items: center;
            gap: 5px;
        }

        @media ( max-width: $device-small ) {
            font-size: 14px;
        }
    }

    &__coin {
        width: 22px;
    }
}
