@import 'styles/variables';

.m-reader {
    display: flex;
    justify-content: space-between;
    padding: 16px;

    &__item {
        width: 100%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        border-radius: 8px;

        img {
            border-radius: 4px;
        }
    }

    &__title {
        font-family: $font-acrom-bold;
        font-size: 16px;
        margin: 0;
    }

    &__no-title {
        min-height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            font-size: 20px;
        }
    }

    .react-pdf__Page__canvas {
        width: 100% !important;
        height: auto !important;
        border-radius: 4px;
    }
}
