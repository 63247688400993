@import 'src/styles/variables.scss';
@import 'src/styles/fonts.scss';

.burger-nav {
    position: absolute;
    // transform: translate(0, -22px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0;
    padding: 0 20px;
    padding-top: 60px;
    padding-bottom: 20px;
    top: 0;
    left: 8px;
    width: 200px;
    height: 100%;
    background: $color-light-white;
    transition: .3s ease;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 9;
    animation: nav-animation .5s;
    overflow-x: auto;
    scrollbar-width: none;

    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &__icon {

        svg {
            width: 24px;
            height: 24px;
        }
    }

    &::-webkit-scrollbar {
        display: none;
    }

    button {
        display: flex;
        gap: 10px;
        align-items: center;
        background: none;
        border: none;
        cursor: pointer;
    }

    span {
        font-size: 17px;
        font-weight: 600;
        text-align: left;
    }
}

@keyframes nav-animation {

    from {
        transform: translate(-100px, 0);
    }

    to {
        transform: translate(0, 0);
    }
}
