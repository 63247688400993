@import 'styles/variables.scss';

.m-image-page {
    display: flex;
    height: calc(100% - 87px);
    flex-direction: column;

    &__info {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 16px;
        flex-grow: 1;
    }

    &__title {
        hyphens: auto;
        font-size: 25px;
        font-family: $font-acrom-bold;
    }

    &__desc {
        word-wrap: break-word;
        hyphens: auto;
        font-size: 16px;
        font-family: $font-acrom-regular;
        opacity: .8;
    }
}
