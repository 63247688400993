@import 'styles/variables';

.new-loader_textarea {
    width: 100%;
    height: 44px;
    max-height: 120px;
    border-radius: 8px;
    font-size: inherit;
    font-family: inherit;
    padding: 11px 16px 11px 16px;
    resize: none;
    border: none;

    &::placeholder {
        color: inherit;
        opacity: .8;
    }

    &:focus {
        outline: none;
    }
}

.new-loader_textarea__error-message {
    margin: 5px 0 0 10px;
}
