@import 'styles/variables.scss';

.m-map {
    padding: 10px 16px;
    border-radius: 13px;
    display: flex;
    gap: 6px;
    cursor: pointer;
    border: none;
    position: relative;

    &__wrapper {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__image {
        border-radius: 4px;
        flex-shrink: 1;
        width: 40%;
        max-height: 90px;
    }

    &__info {
        display: grid;
        grid-template-columns: 1fr;
        gap: 6px;
        text-align: start;
        flex-grow: 1;
    }

    &__title {
        text-transform: capitalize;
        font-family: $font-acrom-bold;
        font-size: 17px;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(92%);
        overflow: hidden;
    }

    &__address {
        font-family: $font-acrom-regular;
        font-size: 13px;
        opacity: .8;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(92%);
        overflow: hidden;
    }

    &__desc {
        font-family: $font-acrom-regular;
        font-size: 13px;
        opacity: .8;
        text-overflow: ellipsis;
        width: calc(92%);
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
        margin-bottom: 15px;
    }

    &__read-more {
        font-size: 13px;
        font-family: $font-acrom-bold;
        position: absolute;
        bottom: 10px;
        right: 16px;
    }
}
