@import 'styles/variables.scss';


.m-survey{
    &-test{
        border-radius: 13px;
        padding: 16px;

        &__heading{
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-bottom: 20px;

            &-questions{
                font-family: $font-acrom-bold;
            }

            &-name{
                font-size: 17px;
                line-height: 20px;
                font-family: $font-acrom-bold;
            }
        }

        &__body{
            margin-bottom: 30px;
            
            &-answers{
                display: flex;
                flex-direction: column;
                gap: 10px;
            }

            &-answer{
                .custom-checkbox {
                    border: 1px solid;
                    padding: 10px 12px;
                    border-radius: 13px;

                    input:checked ~ .checkmark{
                        background: #9D9E9E;
                        &::after{
                            content: '\2713';
                            border: 0;
                            width: 10px;
                            height: 10px;
                            font-size: 15px;
                            transform: none;
                            color: #fff;
                            z-index: 1;
                            top: -3px;
                            left: 1px;
                        }
                    }
                }

                .checkmark-default{
                    border: 2px solid #9D9E9E;
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                }

                .checkmark__text{
                    font-size: 17px;
                    line-height: 22px;
                    font-family: $font-acrom-regular;
                }

                &__text{
                    input{
                        border-radius: 13px;
                    }
                }
            }
        }

        &__actions{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 16px;
        }

        &__futher{
            padding: 0 16px;
            margin-top: 80px;
            color: #C68455;
            font-size: 17px;
            line-height: 22px;
            font-family: $font-acrom-regular;
        }
    }
}