@import 'styles/variables';

.side-menu {
    position: fixed;
    top: 65px;
    color: $color-light-white;
    right: 8px;
    z-index: 20;

    .constructor-overlay {
        top: 0;
        height: 100%;
    }

    @media ( max-width: 960px ) {
        top: 0;
        right: 0;
        border-radius: 0;
        height: 100%;
        z-index: 10000000;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    &--sale {
        top: 145px;

        @media ( max-width: 960px ) {
            top: 0;
        }
    }

    &__close {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;

        button {
            background-color: transparent;
            border: none;
            color: $color-light-white;
            cursor: pointer;
        }

        svg {
            width: 16px;
            height: 16px;
        }
    }

    &__mobile {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: space-between;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        padding: 16px;
        z-index: 20;
        background-color: $color-dark-brown;
        width: fit-content;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(51, 51, 51, .25);

        @media ( max-width: 960px ) {
            height: 100%;
            border-radius: 0;
            box-shadow: none;
            overflow: auto;
            padding-bottom: 90px;
        }
    }

    &__credits {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #555554;
        margin-bottom: 24px;

        p {
            margin: 0;
            margin-top: 12px;
            padding-bottom: 24px;
            width: 243px;
            font-size: 14px;
            font-family: $font-acrom-regular;
        }
    }

    &__items {
        margin: 0;
        display: flex;
        flex-direction: column;
    }

    &__item {
        display: flex;
        align-items: center;
        
        span{
            text-wrap: initial;
            text-align: left;
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        color: $color-primary;
    }

    &__name{
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        svg{
            margin-right: 8px;
        }
    }


    &__value{
        font-family: $font-acrom-regular;
        display: flex;
        align-items: center;
        gap: 6px;
        text-transform: uppercase;
    }

    &__text {
        font-family: $font-acrom-bold;
        font-size: 18px;
        color: $color-light-white;
        white-space: nowrap;
        background-color: transparent;
        border: none;
        cursor: pointer;
        padding: 8px 10px;
        margin: 4px 0;
        border-radius: 8px;

        svg {
            margin-right: 10px;
        }

        &:hover {
            background-color: #555554;
        }
    }

    &__emulator {
        margin-top: 24px;

        button {
            margin: 0;
            width: 100%;
        }
    }
}
