@import 'src/styles/variables.scss';
@import 'src/styles/fonts.scss';

.overlay {
    background-color: rgba(0, 0, 0, .35);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
}
