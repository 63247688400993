/* stylelint-disable no-descending-specificity */
@import 'styles/variables';

.language-choose {
    display: flex;
    color: $color-light-white;
    font-family: $font-acrom-bold;
    font-size: 18px;
    position: relative;
    align-items: center;
    border: none;
    gap: 6px;
    cursor: pointer;
    padding: 8px;
    border-radius: 8px;

    &__wrapper {
        margin-right: 24px;
        position: relative;

        @media ( max-width: 960px ) {
            margin-right: 0;
        }
    }

    &__arrow-down {
        display: flex;

        svg {
            transform: rotate(-90deg);
        }
    }

    &__arrow-up {
        display: flex;

        svg {
            transform: rotate(90deg);
        }
    }

    &__list {
        display: flex;
        position: absolute;
        flex-direction: column;
        background-color: $color-dark-brown;
        padding: 8px;
        border-radius: 10px;
        top: 50px;
        right: 0;

        button {
            background-color: transparent;
            display: flex;
            gap: 6px;
            padding: 8px;
            border: none;
            font-size: 18px;
            align-items: center;
            font-family: $font-acrom-bold;
            cursor: pointer;
            border-radius: 8px;
        }

        &--white {
            background-color: $color-light-white;
            box-shadow: 0 0 0 0 rgba(51, 51, 51, .03), 0 1px 2px 0 rgba(51, 51, 51, .03), 0 4px 4px 0 rgba(51, 51, 51, .03), 0 9px 5px 0 rgba(51, 51, 51, .02), 0 16px 7px 0 rgba(51, 51, 51, 0), 0 25px 7px 0 rgba(51, 51, 51, 0);
            z-index: 5;
            top: 40px;

            button {
                color: $color-dark-brown;

                &:hover {
                    background-color: $color-light-white;
                }
            }

            input[type=radio] {
                display: inline-block;
                -webkit-appearance: none;
                -moz-appearance: none;
                -o-appearance: none;
                appearance: none;
                border-radius: 50%;
                min-height: 16px;
                min-width: 16px;
                cursor: pointer;
                border: 1px solid $color-dark-brown;
            }

            input[type=radio]:checked {
                background: #2396fa;
                border: 4px solid $color-light-white;
                outline: 1px solid #2396fa;
            }
        }

        &--black {
            background-color: $color-dark-brown;
            color: $color-light-white;

            button {
                color: $color-light-white;

                &:hover {
                    background-color: #555554;
                }
            }

            input[type=radio] {
                display: inline-block;
                -webkit-appearance: none;
                -moz-appearance: none;
                -o-appearance: none;
                appearance: none;
                border-radius: 50%;
                min-height: 16px;
                min-width: 16px;
                cursor: pointer;
                border: 1px solid $color-light-white;
            }

            input[type=radio]:checked {
                background: $color-primary;
                border: 4px solid $color-dark-brown;
                outline: 1px solid $color-primary;
            }
        }
    }

    &--white {
        background-color: $color-light-white;
        color: $color-dark-brown;

        &:hover {
            background-color: $color-light-white;
        }

        .language-choose__arrow-down {
            color: $color-dark-brown;
        }

        .language-choose__arrow-up {
            color: $color-dark-brown;
        }
    }

    &--black {
        background-color: $color-dark-brown;
        color: $color-light-white;
        padding: 10px;

        &:hover {
            background-color: #555554;
        }

        @media ( max-width: 960px ) {
            display: none;
        }

        .language-choose__arrow-down {
            color: $color-light-white;
        }

        .language-choose__arrow-up {
            color: $color-light-white;
        }
    }
}
