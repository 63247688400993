@import 'styles/variables.scss';

.m-survey{
    padding: 16px;

    &__items{
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    &__item{
        padding: 6px 10px;
        border-radius: 13px;

        &-heading{
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 13px;
            line-height: 16px;
            font-family: $font-acrom-bold;
            letter-spacing: -.41px;
        }

        &-body{
            margin-bottom: 14px;
            font-size: 17px;
            line-height: 20px;
            font-family: $font-acrom-bold;
            letter-spacing: -.41px;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 225px;
            line-clamp: 1;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;  
            overflow: hidden;
        }

        &-footer{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;


            &-additionals{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
            }

            &-actions{
                flex-grow: 1;
                
                .custom-button__wrapper{
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }
    }
}