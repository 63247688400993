@import 'src/styles/variables.scss';
@import 'src/styles/fonts.scss';

.popup {
    background-color: $color-light-white;
    width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 15px;
    overflow: hidden;
    font-family: $font-acrom-regular;
    color: $color-dark-brown;

    @media ( max-width: $device-small ) {
        width: 343px;
    }

    &__wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__top {
        background-color: #fffab2;

        &--rounded {
            clip-path: ellipse(62% 84% at 51% 15%);
        }
    }

    &__close {
        position: absolute;
        background-color: transparent;
        border: none;
        cursor: pointer;
        right: 20px;
        top: 20px;
    }

    &__body {
        padding: 0 30px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        @media ( max-width: $device-small ) {
            padding: 0 16px;
        }

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-light-grey-2;
        }
    }

    &__title {
        font-size: 24px;
        font-family: $font-acrom-bold;
        text-align: center;
        margin: 0;
        margin-bottom: 16px;
        margin-top: 24px;

        @media ( max-width: $device-small ) {
            font-size: 20px;
        }
    }

    &__bottom {
        margin-bottom: 30px;

        @media ( max-width: $device-small ) {
            margin-bottom: 16px;
        }
    }

    &__buttons {
        display: flex;
        justify-content: center;
        padding: 0 30px;
        margin-top: 24px;
        gap: 10px;

        @media ( max-width: $device-small ) {
            padding: 0 16px;
            margin-bottom: 16px;
            flex-direction: column;
            gap: 10px;

            button {
                width: 100%;
                margin: 0;
            }
        }
    }

    &__error {
        color: #f12727;
        font-size: 18px;
        text-align: center;
        margin-top: 8px;

        @media ( max-width: $device-small ) {
            font-size: 14px;
        }
    }

    // &--fixed-height {
    //     height: 90%;
    // }
}
