@import 'styles/variables.scss';

.menu-pannel {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 200px;
    height: 100%;
    background: $color-light-white;
    transition: .3s ease;
    border-right: 1px solid $color-light-grey-2;

    
    @media ( max-width: $device-medium-2 ) {
        width: 160px;
    }

    @media ( max-width: 960px ) {
        width: 100%;
        flex-direction: row;
        justify-content: flex-start;
        border-top: 1px solid $color-light-grey-2;
    }


    // @media ( max-width: $device-small ) {
    //     width: 125px;
    // }

    &__list {
        padding: 0;
        margin: 0;
        overflow: auto;
        scrollbar-width: none;
        padding-bottom: 5px;
        list-style-type: none;


        @media ( max-width: 960px ) {
            display: flex;
            gap: 8px;
            padding: 8px;
        }    

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &--subcategories {
        background: $color-grey-ultralight;
    }
}
