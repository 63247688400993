@import 'styles/variables.scss';

.switch—checkbox {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 28px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color-light-grey-2;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider::before {
        position: absolute;
        content: '';
        bottom: 2px;
        background-color: $color-light-white;
        -webkit-transition: .4s;
        transition: .4s;
        height: 24px;
        width: 24px;
        left: 3px;
    }

    input:checked + .slider {
        background-color: $color-dark-black;

        &::before {
            background-color: $color-light-white;
            -webkit-transform: translateX(23px);
            -ms-transform: translateX(23px);
            transform: translateX(23px);
        }
    }

    .slider.round {
        border-radius: 34px;
    }

    .slider.round::before {
        border-radius: 50%;
    }
}
