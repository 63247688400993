@import 'styles/variables';

.m-video {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 16px;

    &__item {
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    &__header{
        height: 140px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        position: relative;
        cursor: pointer;

        
        &.loader{
            height: 260px;
            cursor: pointer;
        }
        
        &-title{
            font-family: $font-acrom-bold;
            font-size: 22px;
            line-height: 24px;
            pointer-events: none;
        }

        &-btn{
            padding: 0;
            border: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }

    &__icon{
        pointer-events: none;
        position: absolute;
        width: 55px;
        height: 55px;
        bottom: 50%;
        left: 50%;
        transform: translate(-50%, 50%);
        svg{
            width: 100%;
            height: 100%;
        }
    }

    &__desc{
        font-size: 13px;
        padding: 8px;
        width: 100%;
        opacity: .8;
        font-family: $font-acrom-regular;
        font-weight: 300;

        &-loader {
            padding: 8px;
            width: 100%;
            
            &-title{
                opacity: .8;
                font-family: $font-acrom-bold;
                font-size: 15px;
                line-height: 20px;
            }

            
            &-text{
                opacity: 1;
                font-family: $font-acrom-regular;
                margin-top: 12px;
                font-size: 17px;
                line-height: 22px;
            }
        }
    }
}
