@import 'styles/variables';

.m-swipe-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 100%;
    padding: 30px 0 16px 0;

    &__close {
        background-color: transparent;
        border: none;
        align-self: flex-end;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
    }

    &__text {
        font-size: 24px;
        font-weight: 600;
        color: $color-light-white;
        text-align: center;
    }

    &__image {
        width: 75%;
    }
}
