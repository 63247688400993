@import 'styles/variables';

.emulator-header {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: space-between;

    &--visible {
        padding: 40px 7px 7px 5px;
    }

    &__title {
        align-self: center;
        justify-self: center;
        font-size: 17px;
        font-weight: 600;
    }

    &__profile {
        padding: 0;
        border: none;
        background-color: transparent;
        height: 36px;
        width: 36px;
        cursor: pointer;
    }

    &__todo {
        padding: 0;
        border: none;
        background-color: transparent;
        height: 36px;
        width: 36px;
        cursor: pointer;

        &--wprofile {
            position: absolute;
            right: 50px;
        }
    }

    &__actions{
        display: flex;
        align-items: center;
    }

    &__language{
        margin-right: 16px;
    }
}
