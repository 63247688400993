@import 'styles/variables';

.new-loader_input {
    width: 100%;
    padding: 11px 16px;
    font-size: 15px;
    font-family: $font-acrom-regular;
    border: none;
    border-radius: 8px;

    &::placeholder {
        color: inherit;
        opacity: .6;
    }

    &:focus {
        outline: none;
    }
}

.new-loader_input__error-message {
    margin: 5px 0 0 10px;
}
