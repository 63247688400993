.loader-calendar-image-upload {
    padding: 8px 10px;
    border-radius: 8px;
    border: 1px dashed #9d9e9e;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}

.loader-calendar-image {
    border-radius: 8px;
    width: 40px;
    height: 40px;
}
