@import 'styles/variables';

.empty-notification {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;

    img {
        width: 86px;
    }

    &__message {
        font-size: 20px;
        font-family: $font-acrom-bold;
    }
}
