@import 'styles/variables';

.new-loader_page__video-conference {

    &-link {
        border-radius: 10px;
        background: $color-grey;
        color: $color-light-white;
        text-decoration: none;
        font-size: 17px;
        font-family: $font-acrom-regular, sans-serif;
        padding: 7px 18px;
    }

    &-desc {
        overflow-wrap: break-word;
        width: 100%;
    }
}
