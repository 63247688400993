@import 'styles/variables';

.notification {
    width: 100%;
    padding: 20px 30px;
    display: flex;
    column-gap: 20px;
    position: relative;

    @media ( max-width: 640px ) {
        padding: 20px;
        margin-bottom: 16px;
        border-radius: 8px;
        border: 2px solid $color-light-blue;
    }

    &--read {
        background-color: #fafafa;
    }

    &--unread {
        background-color: #fffef0;
    }

    &__image {
        flex-shrink: 0;
        height: 100%;

        @media ( max-width: 640px ) {
            width: 33px;
        }
    }

    &__header {
        margin: 0;
        font-size: 24px;
        font-family: $font-acrom-bold;
        margin-bottom: 10px;

        @media ( max-width: 640px ) {
            font-size: 18px;
        }
    }

    &__description {
        margin: 0;
        font-size: 18px;
        font-family: $font-acrom-regular;

        @media ( max-width: 640px ) {
            font-size: 14px;
        }
    }

    &__more {
        background-color: transparent;
        border: none;
        font-family: $font-acrom-bold;
        padding: 0;
        font-size: 18px;
        cursor: pointer;
        margin-left: 5px;

        @media ( max-width: 640px ) {
            font-size: 14px;
        }
    }

    &__dot {
        position: absolute;
        background-color: #e73f3f;
        width: 8px;
        height: 8px;
        top: 20px;
        left: 10px;
        border-radius: 50%;
    }

    &__delete {
        color: #9d9e9e;
        position: absolute;
        background-color: transparent;
        border: none;
        padding: 0;
        cursor: pointer;
        right: 13px;
        top: 14px;

        svg {
            width: 20px;
            height: 20px;
        }

        &:hover {
            color: $color-dark-brown;
        }
    }

    &__actions {
        display: flex;
        margin-top: 20px;
        gap: 10px;

        @media ( max-width: 640px ) {
            flex-direction: column;
            row-gap: 10px;
        }
    }

    @media ( max-width: 640px ) {

        .custom-button__wrapper {
            width: 100%;

            button {
                width: 100%;
                margin: 0;
            }
        }
    }
}
