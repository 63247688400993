@import 'styles/variables';

.navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-dark-brown;
    height: 80px;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 12;

    @media ( max-width: 960px ) {
        height: 76px;
    }

    .header-button {
        background: none;
        border: none;
        height: 100%;
        cursor: pointer;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &--yellow {
            color: $color-primary;
        }

        span {
            @media ( min-width: 1200px ) and ( max-width: 1260px ) {
                width: 77px;
            }
        }

        &__close {
            width: 46px;
        }

        &:nth-child(2) {
            border-left: 1px solid #555554;
            @media ( max-width: 960px ) {
                margin-left: 10px;
                padding-left: 10px;
            }
        }
        @media ( max-width: 960px ) {
            svg{
                height: 100%;
                width: 35px;
            }
        }
    }
    &__header-preview{
        margin-left: 16px;
        .custom-button{
            font-size: 12px;
            line-height: 14px;
        }
        svg{
            width: 12px;
            height: 12px;
        }
    }

    &__wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0 20px;
        gap: 10px;
        @media ( max-width: 960px ) {
            flex-wrap: wrap;
            gap: 0;
            margin: 0;
        }
    }

    &__trial {
        color: $color-light-white;
        font-family: $font-acrom-bold;
        font-size: 24px;
        text-align: center;
        min-width: 160px;
        line-height: 32px;

        @media ( max-width: $device-medium-4 ) {
            font-size: 20px;
        }

        @media ( max-width: 960px ) {
            width: 100%;
            margin: 0 0 8px;
            font-size: 16px;
            line-height: 18px;
            padding: 4px 16px;
            border-bottom: 1px solid #555554;
        }

        @media ( max-width: $device-small ) {
            font-size: 14px;
        }
    }

    &__back-to-promo {
        display: flex;
        align-items: center;
        font-family: $font-acrom-bold;
        font-size: 20px;
        color: $color-primary;
        text-align: center;
    }

    // don`t know why but it`s only works without &
    // because css is cascade and if it is inside it overrides outer style

    .navigation__menu-icon {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
    }

    &__account-info {
        display: flex;
        align-items: center;
    }

    &__menu-regular {
        display: flex;
        font-family: $font-acrom-regular;
        font-size: 16px;
        text-align: left;
        width: 100%;

        img {
            width: 18px;
            height: 18px;
        }
    }

    &__icons {
        display: flex;
        align-items: center;
        height: 35px;
        @media ( max-width: 960px ) {
            margin-right: 16px;
        }
    }
}

.navigation__sale {
    top: 80px;
}
