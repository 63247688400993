@import 'styles/variables';

.right-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    flex-shrink: 0;
    background: $color-light-blue;
    padding: 20px 35px;
    height: calc(100vh - 81px);
    top: 81px;
    z-index: 10;
    box-sizing: border-box;
    overflow-y: auto;
    gap: 20px;
    right: 0;

    &__wrapper {
        position: relative;
        z-index: 1;
    }

    @media ( max-width: $device-medium-1 ) {
        display: none;
    }

    &--sale {
        height: calc(100vh - 160px);
        top: 160px;
    }
    &.next-feature-show{
        padding-bottom: 87px;
    }
}

.right-emulator {
    position: sticky;
    z-index: 1;
    top: 0;

    &__screen {
        padding: 40px 7px 7px 5px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__title {
        font-size: 17px;
        font-weight: 600;
        padding-top: 3px;
        align-self: center;
    }

    &__page {
        height: 100%;
    }
}
