@import 'styles/variables';

.m-chat {
    display: flex;
    gap: 16px;
    padding: 16px;
    flex-direction: column;

    &__item {
        border-radius: 8px;
        padding: 16px;
    }

    &__desc {
        padding: 12px 0;
        overflow-wrap: break-word;
        width: 100%;
        opacity: .8;
    }
    &__header{
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
    }

    .link-icon-text__title{
        font-size: 17px;
    }
}
