@import 'styles/variables.scss';

.m-employee {
    padding: 12px;
    border-radius: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    border: none;
    position: relative;

    &__wrapper {
        padding: 12px;
        display: grid;
        gap: 12px;
        grid-template-columns: 1fr 1fr;
    }

    &__image {
        border-radius: 8px;
        flex-shrink: 1;
        width: 100%;
        max-height: 90px;
        margin-bottom: 12px;
    }

    &__info {
        display: grid;
        grid-template-columns: 1fr;
        text-align: start;
    }

    &__title {
        text-transform: capitalize;
        font-family: $font-acrom-regular;
        font-size: 17px;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(92%);
        overflow: hidden;
        margin-bottom: 4px;
    }

    &__position {
        font-family: $font-acrom-regular;
        font-size: 13px;
        opacity: .8;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(92%);
        overflow: hidden;
    }
}
