@import 'styles/variables.scss';

.custom-input {
    font-family: $font-acrom-regular;
    font-size: 18px;
    width: 100%;
    height: 50px;
    padding: 16px 24px;
    border-radius: 8px;
    border: 1px solid $color-dark-grey-lighter;
    background-color: $color-light-grey-lighter;

    &:focus {
        outline: none;
        border: 1px solid $color-primary;
        box-shadow: inset 0 0 0 1px $color-primary;
    }

    &__wrapper {
        width: 100%;
    }

    &__error {
        border-color: $color-dark-red;

        &::placeholder {
            color: $color-dark-red;
        }

        &:focus {
            outline: none;
            border: 1px solid $color-dark-red;
            box-shadow: inset 0 0 0 1px $color-dark-red;
        }
    }

    &__error-message {
        font-family: $font-acrom-regular;
        font-size: 16px;
        color: $color-dark-red;
        margin: 0;
        margin-top: 10px;
        display: flex;
        align-items: center;

        svg{
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }
    }
}

.custom-label {
    display: flex;
    text-align: start;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 12px;
    font-family: $font-acrom-bold;

    @media ( max-width: $device-small-2 ) {
        font-size: 16px;
        line-height: 18px;
    }
}

::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
}
