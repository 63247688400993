@import 'styles/variables.scss';


.cloud-tabs{
    margin-bottom: 2rem;
    .cloud-tabs{
        &__wrapper{
            display: flex;
            padding: 8px;
            border-radius: 15px;
            border: 2px solid $color-light-blue;
            width: fit-content;
            overflow: auto;

            @media (max-width:$device-small) {
                width: 100%;
            }
        }

        &__button button{
            padding: 8px 42px;

            @media (max-width:$device-medium-4) {
                padding: 8px 24px;
            }
            @media (max-width:$device-small-3) {
                padding: 8px 16px;
            }
        }
    }
    &__emulator{
        padding: 8px 16px 0;
        border-bottom: .33px solid #FFFFFF61;
        margin-bottom: 8px;

        .cloud-tabs{
            &__wrapper{
                display: flex;
                flex-wrap: nowrap;
                overflow: auto;
                gap: 16px;

                &::-webkit-scrollbar{
                    height: 0;
                }
            }

            &__button{
                cursor: pointer;
                border: 0;
                outline: none;
                border-bottom: 3px solid transparent;
                background: transparent;
                font-family: $font-acrom-regular;
                width: 86px;
                min-width: 86px;
                padding: 0 0 4px;
                
            }
        }
    }
}