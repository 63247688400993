@import "styles/variables.scss";

.m-booking{
    &__managment{
        padding: 12px;

        &-tabs{
            display: grid;
            grid-template-columns: 1fr 1fr;
            border-bottom: .33px solid #FFFFFF61;
        }

        &-tab{
            padding: 4px;
            background: transparent;
            border: 0;
            border-bottom: 3px solid;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -.5px;
            font-family: $font-acrom-bold;
            cursor: pointer;
            outline: none;

            &:not(.active){
                border-color: transparent;
            }
        }

        &-top{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 12px 0 24px;
            width: 100%;
            padding: 8px;
            border-radius: 12px;
            gap: 4px;
            
            path{
                stroke: currentColor;
            }

            &-text{
                flex-grow: 1;
                font-family: $font-acrom-bold;
                font-size: 17px;
                line-height: 22px;
                letter-spacing: -.41px;
            }

        }


        .m-schedule-item{
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 12px;
            margin-bottom: 16px;
            padding: 8px;
            border-radius: 12px;
            border: none;
            width: 100%;
            cursor: pointer;

            &__image{
                border-radius: 8px;
                overflow: hidden;
                min-width: 72px;
                height: 72px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            &__info{
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            &__name{
                font-size: 17px;
                line-height: 22px;
                letter-spacing: -.41px;
                font-family: $font-acrom-bold;
                margin-bottom: 2px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: 150px;
            }

            &__description{
                opacity: .8;
                font-size: 13px;
                line-height: 16px;
                letter-spacing: -.41px;
                font-family: $font-acrom-regular;
                margin-bottom: 9px;
                text-align: left;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 150px;
            }

            &__service{

                &-info{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 4px;
                    margin-bottom: 3px;
                }

                &-price{
                    font-size: 17px;
                    line-height: 22px;
                    letter-spacing: -.41px;
                    font-family: $font-acrom-bold;
                }

                &-termin{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 4px;
                    font-size: 13px;
                    line-height: 16px;
                    letter-spacing: -.08px;
                    font-family: $font-acrom-bold;
                    opacity: .8;
 
                    svg{
                        width: 16px;
                        height: 16px;
                    }
                }
                &-dot{
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .custom-dot{
                        width: 3px;
                        height: 3px;
                        border-radius: 50%;
                    }
                }
            }
            &__more{
                display: flex;
                justify-content: flex-end;
                font-size: 13px;
                line-height: 16px;
                letter-spacing: -.08px;
                gap: 4px;
                font-family: $font-acrom-bold;

            }
        }

        .booked-services{
            padding: 24px 0 0;

            .booked-service{
                padding: 8px 12px;
                border-radius: 12px;
                overflow: hidden;
                border: 0;

                &__title{
                    font-size: 17px;
                    line-height: 22px;
                    letter-spacing: -.41px;
                    font-family: $font-acrom-bold;
                    margin-bottom: 12px;
                }

                &__rows{
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    margin-bottom: 4px;
                }

                &__row{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    margin-bottom: 8px;

                    &:nth-child(even){
                        align-items: end;
                    }
                }

                &__label{
                    opacity: .8;
                    font-size: 13px;
                    line-height: 16px;
                    letter-spacing: -.41px;
                    font-family: $font-acrom-bold;
                    margin-bottom: 4px;
                }
                &__value{
                    font-size: 15px;
                    line-height: 20px;
                    letter-spacing: -.5px;
                    font-family: $font-acrom-bold;
                    text-align: left;
                }

                &__more{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 4px;
                    border: 0;
                    background: transparent;
                    width: 100%;
                    cursor: pointer;

                    &-title{
                        font-size: 13px;
                        line-height: 16px;
                        letter-spacing: -.08px;
                        font-family: $font-acrom-bold;
                    }

                    svg{
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }
}