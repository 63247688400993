@import 'styles/variables.scss';

.m-blog-item {
    display: flex;
    height: calc(100% - 87px);
    flex-direction: column;

    &__info {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 16px;
        flex-grow: 1;
    }

    &__title {
        hyphens: auto;
        font-size: 25px;
        font-family: $font-acrom-bold;
    }

    &__date {
        font-size: 13px;
        font-family: $font-acrom-regular;
        opacity: .8;
        padding-bottom: 10px;
        border-bottom: .5px solid;
    }

    &__desc {
        hyphens: auto;
        font-size: 16px;
        font-family: $font-acrom-regular;
        opacity: .8;

        a {
            color: inherit;
        }
    }
    .swiper{
        width: 100%;
        img{
            height: 100%;
            width: 100%;
        }
        &-pagination{
            --swiper-pagination-top: unset;
                --swiper-pagination-bottom: 16px;
                --swiper-pagination-color:#F6F3F9;
                --swiper-pagination-bullet-opacity:1;
                --swiper-pagination-bullet-inactive-opacity: 1;
                --swiper-pagination-bullet-inactive-color:#F6F3F9CC;
                --swiper-pagination-bullet-width: 4px;
                --swiper-pagination-bullet-active-width: 12px;
                --swiper-pagination-bullet-border-radius: 50px;
                --swiper-pagination-bullet-size: 4px;
                --swiper-pagination-bullet-horizontal-gap: 2px;

                .swiper-pagination-bullet-active{
                    width: 12px;
                }
        }
    }
}
