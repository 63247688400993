.new-loader-button {
    padding: 13px 20px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 600;
    font-size: 17px;
    width: fit-content;
}
