@import 'styles/variables.scss';

.m-payment-item{
    &__wrapper{
        height: 100%;
        padding: 24px 8px;
        display: flex;
        flex-direction: column;
    }

    &__title{
        font-size: 20px;
        font-family: $font-acrom-bold;
        line-height: 24px;
        letter-spacing: -0.38px;

        &-wrapper{
            padding-bottom: 24px;
            border-bottom: 1px solid;
            margin-bottom: 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: nowrap;
            gap: 5px;
        }
    }
    &__status{
        white-space: nowrap;
        padding: 4px 8px;
        border-radius: 50px;
        font-size: 13px;
        line-height: 16px;
        font-family: $font-acrom-bold;

        &.success{
            background-color: #4CAF501A;
            color: #4CAF50;
        }

        &.cancel{
            background-color: #FF98001A;
            color: #FF9800;
        }
        &.processing{
            background-color: $color-grey-ultralight;
            color: $color-dark-grey-lighter;
        }
    }

    &__info{
        flex-grow: 1;
    }

    &__block{
        &-title{
            opacity: .8;
            font-size: 13px;
            line-height: 14px;
            font-family: $font-acrom-bold;
            margin-bottom: 4px;
        }
        &-subtitle{
            font-size: 17px;
            line-height: 22px;
            letter-spacing: -0.41px;
            margin-bottom: 16px;
        }
    }

    &__actions{
        padding-top: 24px;
        border-top: 1px solid;
        
        .custom-button{
            width: 100%;
            pointer-events: none;
        }
    }
}