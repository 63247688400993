@import 'styles/variables';

.m-priority-todo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 10px;
    font-weight: 600;

    &__category {
        display: flex;
        gap: 6px;
        align-items: center;

        svg {
            width: 16px;
            height: 16px;
        }
    }

    &__options {
        display: flex;
        gap: 6px;
        align-items: center;
    }

    &__filter {
        display: flex;
        gap: 6px;
        font-size: 13px;
        align-items: center;
        border: none;
        background-color: transparent;
        cursor: pointer;
        font-weight: 600;

        svg {
            width: 16px;
            height: 16px;
        }
    }
}
