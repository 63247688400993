@import 'styles/variables.scss';

.link-icon-text {
    display: flex;
    flex-direction: column;
    gap: 5px;

    &__title {
        display: flex;
        gap: 5px;
        align-items: center;
        line-height: 22px;
    }

    &__desc {
        opacity: .8;
        overflow-wrap: break-word;
        font-family: $font-acrom-regular;
        width: calc(92%);
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
    }

    &--small {
        font-family: $font-acrom-regular;
        font-size: 12px;
    }

    &--medium {
        font-family: $font-acrom-regular;
        font-size: 15px;
    }

    &--large {
        font-family: $font-acrom-regular;
        font-size: 18px;
        gap: 8px;
    }
}
