@import 'styles/variables.scss';

.constructor-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    height: calc(100vh - 81px);
    position: fixed;
    top: 81px;
    background: $color-primary;
    z-index: 10;

    @media ( max-width: 960px ) {
        position: fixed;
        height: auto;
        flex-direction: column-reverse;
        width: 100%;
        z-index: 20;
        top: unset;
        bottom: 0;
    }
    &.next-feature-show{
        padding-bottom: 87px;

        @media (max-width:$device-small-1) {
            padding-bottom: 0;
        }
    }
}

.constructor-nav--sale {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    height: calc(100vh - 160px);
    position: fixed;
    top: 160px;
    background: $color-primary;
    z-index: 10;

    @media ( max-width: 960px ) {
        position: fixed;
        height: auto;
        flex-direction: column-reverse;
        width: 100%;
        z-index: 20;
        top: unset;
        bottom: 0;
    }
    &.next-feature-show{
        padding-bottom: 87px;

        @media (max-width:$device-small-1) {
            padding-bottom: 0;
        }
    }
}

.constructor-overlay {
    width: 100%;
    position: fixed;
    height: calc(100vh - 53px);
    top: 53px;
    z-index: 15;
    background: rgba(0, 0, 0, .25);

    &--sale {
        height: calc(100vh - 132px);
        top: 132px;
    }

    @media ( max-width: 960px ) {
        display: none;
    }
}
