@import 'styles/variables';

.m-conference {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 16px;

    &__item {
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 10px 8px;
    }

    &__desc {
        overflow-wrap: break-word;
        width: 100%;
        font-size: 13px;
        opacity: .8;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
