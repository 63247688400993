@import "styles/variables.scss";

.new-loader-open-button {
    padding: 0;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    width: fit-content;

    svg{
        width: 16px;
        height: 16px;
        margin-left: 4px;
    }
}
