@import 'styles/variables';

.m-categories-todo {
    display: flex;
    gap: 10px;
    overflow-x: scroll;
    align-items: center;

    &__item {
        padding: 7px 12px;
        white-space: nowrap;
        font-size: 13px;
        border-radius: 8px;
        font-weight: 600;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        gap: 5px;
        align-items: center;

        svg {
            width: 16px;
            height: 16px;
        }
    }

    &__active {
        border-radius: 8px;
        border: 1px solid;
    }

    &::-webkit-scrollbar {
        display: none;
    }
}
