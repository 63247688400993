@import 'styles/variables.scss';
@import 'styles/fonts.scss';

.custom-checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: inherit;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .checkmark {
        position: absolute;
        height: 22px;
        width: 22px;
        border-radius: 5px;
        border: 2px solid $color-dark-brown;
        background-color: $color-primary;

        &::after {
            content: '';
            position: absolute;
            display: none;
            left: 6px;
            top: 2px;
            width: 9px;
            height: 14px;
            border: solid $color-primary;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
        }

        &__text {
            margin-left: 30px;
            font-family: $font-acrom-regular;
        }
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .checkmark {
            background-color: $color-dark-black;

            &::after {
                display: block;
            }
        }
    }

    .grey {
        background-color: $color-light-grey;
    }

    .checkmark-default {
        background-color: $color-light-white;
        border: 2px solid $color-dark-brown;

        &::after {
            content: '';
            position: absolute;
            display: none;
            left: 5px;
            top: 1px;
            width: 9px;
            height: 13px;
            border: solid $color-dark-brown;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
        }
    }

    .checkmark-default--input {

        &:checked ~ .checkmark-default {
            background-color: $color-light-white;
        }
    }

    &.error .checkmark-default{
        border-color: $color-dark-red;
    }
    
    &.error .checkmark__text{
        color: $color-dark-red;
    }
}
