@import 'styles/variables';

.notifications {
    width: 480px;
    position: relative;
    overflow-y: auto;
    background-color: $color-light-white;
    color: $color-dark-brown;
    margin-left: 0;

    &__wrapper {
        position: fixed;
        top: 81px;
        right: 0;
        width: 100vw;
        z-index: 20;
        display: flex;
        height: calc(100vh - 81px);

        @media ( max-width: 960px ) {
            top: 52px;
            height: calc(100vh - 52px);
        }

        &--sale {
            top: 160px;
            height: calc(100vh - 160px);

            @media ( max-width: 960px ) {
                top: 132px;
                height: calc(100vh - 132px);
            }
        }
    }

    &__overlay {
        flex-grow: 1;
        background-color: rgba(0, 0, 0, .1);
    }

    &__header {
        width: 100%;
        padding: 16px 30px;
        background-color: $color-light-white;
        position: absolute;
        top: 0;
        right: 0;
        box-sizing: border-box;
        font-family: $font-acrom-bold;
        font-size: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        div {
            display: flex;
            align-items: center;
        }

        &__title{
            margin: 0 20px 0 0;
        }
    }

    &__close {
        background-color: transparent;
        border: none;
        cursor: pointer;
    }

    &__messages {
        padding-top: 70px;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}
