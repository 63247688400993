@import 'styles/variables';

.m-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50px;
    z-index: 2;
    background: rgba(0, 0, 0, .25);
    backdrop-filter: blur(3px);

    &__wrapper {
        height: 100%;
        padding: 26px;

        &--center {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &--top {
        z-index: 3;
    }
}
