@import 'styles/variables';

.credits {
    display: flex;
    align-items: center;
    padding: 4px;
    border-radius: 10px;
    background-color: #555554;

    &__ai-target{
        background: none;
        border: none;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
    }

    button {
        margin: 0;
    }

    &__coin {
        width: 28px;
        height: 28px;
        margin-left: 6px;
        margin-right: 8px;
    }

    &__amount {
        font-family: $font-acrom-bold;
        font-size: 18px;
        color: $color-light-white;
    }

    &--trial {
        padding: 6px;
    }

    .custom-button__wrapper {
        margin-left: 16px;
    }
}
