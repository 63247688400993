@import 'styles/variables';

.m-gallery {
    display: flex;
    width: 100%;
    gap: 16px;
    padding: 16px;

    &__item {
        border: none;
        border-radius: 8px;
        padding: 0;
        cursor: pointer;
    }

    &__image {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    &__desc {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        padding: 8px 0;
        overflow-wrap: break-word;
        width: 100%;
        opacity: .8;
    }
}
