@import 'styles/variables.scss';

.custom-button {
    font-size: 18px;
    line-height: 22px;
    font-family: $font-acrom-bold;
    display: flex;
    column-gap: 5px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    text-transform: none;
    width: fit-content;
    white-space: nowrap;
    border: none;
    cursor: pointer;

    &__wrapper {
        position: relative;
    }

    @media ( max-width: $device-small-2 ) {
        font-size: 16px;
        line-height: 19px;
    }

    &:disabled {
        opacity: .5;
        pointer-events: none;
        cursor: not-allowed;
    }

    &-design {

        &--primary {
            color: $color-primary;
            background-color: $color-dark-brown;

            &:hover {
                background-color: #555554;
            }
        }

        &--secondary {
            color: $color-dark-brown;
            background-color: $color-primary;

            &:hover {
                background-color: #fff466;
            }
        }

        &--white {
            color: $color-dark-brown;
            background-color: $color-light-white;

            &:hover {
                background-color: #ececec;
            }
        }

        &--cancel {
            color: $color-dark-brown;
            background-color: $color-light-blue;

            &:hover {
                background-color: #ececec;
            }
        }

        &--delete {
            color: $color-light-white;
            background-color: $color-red;
        }

        &--grey {
            color: $color-grey;
            background-color: $color-light-grey-2;
            cursor: not-allowed;

            &:hover {
                box-shadow: 0 0 10px rgba(0, 0, 0, 0);
            }
        }

        &--accept {
            color: $color-light-white;
            background-color: $color-green;
            width: 100%;
        }

        &--reject {
            color: $color-dark-red;
            background-color: $color-light-white;
            box-shadow: inset 0 0 0 1px $color-dark-red;
            width: 100%;
        }

        &--disabled {
            color: $color-dark-brown;
            background-color: $color-grey-ultralight;
            opacity: .5;
            cursor: not-allowed;
            pointer-events: none;
        }
    }

    &-size {

        &--small {
            padding: 8px 16px;

            @media ( max-width: $device-small-2 ) {
                padding: 8px 16px;
            }
        }

        &--medium {
            padding: 12px 24px;

            @media ( max-width: $device-small-2 ) {
                padding: 12px 18px;
            }
        }

        &--large {
            padding: 16px 32px;

            @media ( max-width: $device-small-2 ) {
                padding: 12px 18px;
            }
        }
    }
}
