@import 'styles/variables.scss';

.m-employee-item {
    display: flex;
    height: 100%;
    flex-direction: column;

    &__info {
        display: flex;
        flex-direction: column;
        padding: 0 16px;
        flex-grow: 1;
    }

    &__title {
        hyphens: auto;
        font-size: 25px;
        line-height: 27px;
        font-family: $font-acrom-bold;
        margin-bottom: 8px;
        padding-top: 24px;
    }

    &__measure {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: 24px 0;
    }

    &__exp {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 13px;
        gap: 4px;
        border-right: 1px solid;

        span {
            font-size: 17px;
        }
    }

    .no-rating{
        font-size: 13px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        
        path{
            stroke:currentColor
        }
    }

    &__blocks {
        hyphens: auto;
        border-top: 1px solid;
        font-size: 17px;
        line-height: 19px;
        font-family: $font-acrom-regular;
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding-top: 24px;
        margin-bottom: 24px;

        a {
            color: inherit;
        }
    }

    &__heading {
        opacity: .8;
        margin-bottom: 12px;
        font-family: $font-acrom-bold;
        font-size: 15px;
        line-height: 17px;
    }

    &__socials {
        display: flex;
        gap: 16px;
    }

    &__service {
        padding: 8px;
        border-radius: 12px;
        display: flex;
        gap: 12px;
        border: 1px solid;
    }

    &__s-info {
        display: grid;
    }

    &__s-image {
        border-radius: 4px;
        flex-shrink: 1;
        width: 70px;
        height: 70px;
    }

    &__s-title {
        text-transform: capitalize;
        font-family: $font-acrom-bold;
        font-size: 17px;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(92%);
        overflow: hidden;
    }

    &__s-desc {
        font-family: $font-acrom-regular;
        font-size: 13px;
        opacity: .8;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(92%);
        overflow: hidden;
    }

    &__s-values {
        display: flex;
        gap: 6px;
        // margin-bottom: 20px;
        font-size: 13px;
    }

    &__s-tag {
        position: relative;
        padding: 4px 8px;
        font-weight: 600;
        display: flex;
        gap: 4px;
        align-items: center;
    }

    &__s-background {
        border-radius: 999px;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: .15;
        top: 0;
        right: 0;
    }
}
