@import 'styles/variables';

.mobile-welcome {
    width: 100%;
    height: 100%;
    padding: 16px;
    text-align: center;

    &__logo {
        font-size: 30px;
        font-family: $font-dameron;
        margin: 0;
        margin-top: 130px;
        margin-bottom: 20px;
        min-height: 45px;

        &-img{
            margin-top: 130px;
            width: auto;
            height: 150px;
            object-fit: contain;
        }
    }

    &__heading {
        font-size: 26px;
        font-family: $font-acrom-bold;
        letter-spacing: -2px;
        margin: 0;
        margin-bottom: 10px;
    }

    &__subheading {
        font-size: 16px;
        font-family: $font-acrom-regular;
        margin: 0;
        opacity: .8;
    }

    button {
        width: 100%;
        font-size: 17px;
        padding: 14px;
        border: none;
        border-radius: 10px;
        cursor: pointer;
    }
}
