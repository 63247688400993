@import 'styles/variables';

.m-smm {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 16px;

    &__item {
        padding: 10px 16px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__desc {
        font-size: 13px;
    }

    &__header{
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }
    .link-icon-text__title{
        font-size: 17px;
    }
}
