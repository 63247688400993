@import 'styles/variables.scss';

.m-booking {
    padding: 8px;
    border-radius: 13px;
    display: flex;
    gap: 6px;
    cursor: pointer;
    border: none;
    position: relative;

    &__wrapper {
        padding: 12px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__image {
        border-radius: 4px;
        flex-shrink: 1;
        width: 72px;
        max-height: 90px;
    }

    &__info {
        display: grid;
        grid-template-columns: 1fr;
        gap: 6px;
        text-align: start;
        flex-grow: 1;
    }

    &__title {
        line-height: 22px;
        text-transform: capitalize;
        font-family: $font-acrom-bold;
        font-size: 17px;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(92%);
        overflow: hidden;
    }

    &__location {
        font-family: $font-acrom-regular;
        font-size: 13px;
        opacity: .8;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(92%);
        overflow: hidden;
        line-height: 16px;

        svg {
            width: 16px;
            height: 16px;
        }
    }

    &__amount {
        opacity: .8;
        display: flex;
        align-items: center;
        gap: 4px;
        font-family: $font-acrom-regular;
        font-size: 13px;
    }

    &__read-more {
        font-size: 13px;
        font-family: $font-acrom-bold;
        position: absolute;
        bottom: 10px;
        right: 16px;
    }

    &__further-steps{
        color: #C68455;
        font-size: 17px;
        line-height: 22px;
        letter-spacing: -.41px;
        font-family: $font-acrom-regular;
        text-align: center;
        max-width: 275px;
        margin: 55px auto 0;
    }
}
