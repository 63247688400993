@import 'src/styles/variables.scss';
@import 'src/styles/fonts.scss';

.map-location-btn {
    position: absolute;
    z-index: 2;
    top: 5px;
    left: 5px;
    background-color: $color-light-white;
    box-shadow: 0 6px 12px 2px rgba(34, 60, 80, .2);
    border: none;
    color: $color-dark-brown;

    .location-icon {
        width: 20px;
        height: 25px;
    }
}
